import React from 'react';
import './faq-list.scss';

export interface FAQListProps {
  list: FaqItem[];
}

const FAQList: React.FC<FAQListProps> = ({ list }: FAQListProps) => {
	return (
		<ul className="faq-list">
			{list.map((item: FaqItem, index: number) => (
				<li className="faq-list__item" key={index}>
					<article>
						<h3 className="atmospheric-text faq-list__item-header">{item.question}</h3>
						<p className="corbel-text faq-list__item-content" dangerouslySetInnerHTML={{ __html: `${item.answer}`}}/>
					</article>
				</li>
			))}
		</ul>
	);
};

export { FAQList };
