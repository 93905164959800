import { DefaultLayout } from '@/layouts/default-layout/DefaultLayout';
import LoliAvatarImage from '@images/faqs/loli.png';
import '../styles/global.scss';
import '../styles/faq-page.scss';
import { FAQList } from '@/components/faq-list';
import { Button } from '@/components/button';
import { configs } from '@/constants/configs';
import { NAV_SOCIALMEDIALIST } from '@/constants/social-media';
import { FAQ_LIST } from '@/constants/faqs';

const pageName: string = 'FAQ.s';

export default function FAQs() {
	return (
		<DefaultLayout
			className="faq-page"
			pageTitle={`${configs.defaultPageTitle} - ${pageName}`}
		>
			<div className="faq__decorative-image-wrapper">
				<img src={LoliAvatarImage} alt="" />
			</div>
			<div className="faq__main-content">
				<div className="faq__header-wrapper" data-content={pageName}>
					<h2 className="faq__header atmospheric-text">{pageName}</h2>
				</div>
				<FAQList list={FAQ_LIST} />
				<div className="faq__section-footer">
					<p className="corbel-text">
						For more information about the project, hop on to our
						Discord!
					</p>
					<Button type="diagonal" theme="secondary">
						<a
							href={NAV_SOCIALMEDIALIST.discord}
							target="_blank"
						>
							Join our discord
						</a>
					</Button>
				</div>
			</div>
		</DefaultLayout>
	);
}
