export const FAQ_LIST: FaqItem[] = [
	{
		question: 'What is PLAYGROUND?',
		answer: 'Parallel to the world we live, work and play in, the Playground Metaverse is infused with core elements from MMORPGs and coupled with stunning graphics.<br/><br/> We call this the MMRPV- Massively Multiplayer Role Playing \'Verse',
	},
	{
		question: 'What is DarkLight?',
		answer: 'DarkLight is PLAYGROUND’s very own MMORPG. The game is set in a dystopian future world where time has been glitched to loop ceaselessly in 168 hour cycles. Time has also taken the literal form of a tradable currency. DarkLight’s world is split into 6 distinct realms, with only its central city, Eihcra, shielded from the erratic effects of the time rift. People in DarkLight practise alchemy, which is a fusion of meta-science and ancient arts. In the game, players get to choose their paths between two factions. The Empowered subscribes to the belief that Time should be mastered and controlled; while the Enlightened preaches that Time should be reverted to its natural state.',
	},
	{
		question: 'Is the game free-to-play?',
		answer: 'Yes. DarkLight is completely free to play so that everyone can enjoy our content.',
	},
	{
		question: 'What are play-to-earn aspects in the game?',
		answer: 'Play-to-earn concepts exist in both PLAYCE and DarkLight. By clearing missions, expeditions and tasks, users will be issued various rewards which can come in the form of NFTs (Gears, Skins, Mounts etc) and tradable currencies which carries value in the open market.',
	},
	{
		question: 'What is PLAYCE?',
		answer: 'PLAYCE is a central integrated entertainment hub. Its architecture is styled based on nostalgic video game arcades, infused with futuristic design inspirations. It is where PLAYGROUND’s users can enjoy a myriad of entertainment contents from countless vendors, developers and publishers. One could think of PLAYCE as a Web 3.0 version of Apple’s AppStore or Google’s PlayStore. PLAYCE is named as such as it is envisioned to be the Place for Play. It is also the abbreviation for “PLAYGROUND’s Center of Entertainment”.',
	},
	{
		question: 'When will the Metaverse or DarkLight be launched?',
		answer: 'The Metaverse’s Alpha launch is targeted to occur at the end of 2022. DarkLight is looking to roll out in mid 2023.',
	},
];
